<template>
    <div class="terminal-data">
        <div class="title">用户分析</div>
        <div class="chat-content">
            <el-row :gutter="10">
                <el-col :span="12">
                    <div class="chat-item">
                        <p class="chat-title">新增用户注册动态</p>
                        <div class="search-form">
                            <div>
                                <span class="form-label">查询日期</span>
                                <el-select v-model="reqCountDatevalue" placeholder="请选择" style="width: 130px">
                                    <el-option
                                            v-for="item in userCountOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-button type="primary" @click="getChart2Data">查询</el-button>
                            </div>
                        </div>
                        <div class="totle-data">
                            <div>
                                <span>注册总计：{{ sumReq }}人</span>
                            </div>
<!--                            <span style="margin-right: 20px">H5注册：{{ countReqData.h5 || 0 }}人</span>-->
<!--                            <span>Android注册：{{ countReqData.android || 0 }}人</span>-->
                        </div>
                        <div class="chat-box" ref="chat2"></div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="chat-item">
                        <p class="chat-title">用户活跃时间段</p>
                        <div class="search-form">
                            <div>
                                <span class="form-label">查询日期</span>
                                <el-date-picker
                                        style="width: 150px; margin-left: 10px"
                                        v-model="userActiveDay"
                                        type="date"
                                        :picker-options="pickerOptions"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </div>
                            <div>
                                <el-button type="primary" @click="getChart3Data">查询</el-button>
                            </div>
                        </div>
                        <div class="totle-data">
                            <div>
                                <span>总用户数：{{ userNum.user }}</span>
                            </div>
                        </div>
                        <div class="chat-box" ref="chat3">

                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="chat-item">
                        <p class="chat-title">用户性别比列</p>
                        <div class="search-form" style="height: 41px"></div>
                        <div class="totle-data">
                            <div>
                                <span>总用户数：{{ userNum.user }}人</span>
                            </div>
                        </div>
                        <div class="chat-box" ref="chat4"></div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="chat-item">
                        <p class="chat-title">用户年龄比列</p>
                        <div class="search-form" style="height: 41px"></div>
                        <div class="totle-data">
                            <div>
                                <span>当前总用户数：{{ userNum.user }}人</span>
                            </div>
                        </div>
                        <div class="chat-box" ref="chat5">

                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {activeTime, ageRatio, countReqUserNum, countUserNum, reqUpdates, sexCount, totalDynamics} from "../../api/chart"

export default {
    name: "terminalData",
    data() {
        return {
            userCountDay: new Date(),
            loginCountDay: new Date(),
            reqCountDay: new Date(), //新增用户注册动态-查询日期
            userActiveDay: new Date(), //新增用户注册动态-查询日期
            userCountOptions: [{
                value: '1',
                label: '近7天'
            }, {
                value: '2',
                label: '近15天'
            }, {
                value: '3',
                label: '近30天'
            },
                {
                    value: '4',
                    label: '近1年'
                }],
            options: [{
                value: '1',
                label: '按日查询'
            }, {
                value: '2',
                label: '按周查询'
            }, {
                value: '3',
                label: '按月查询'
            }, {
                value: '4',
                label: '按年查询'
            }],
            value: '1',
            userCountDatevalue: "1",
            loginCountDateValue: "1",
            reqCountDatevalue: "1",
            userActiveDatevalue: "1",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            myChart1: null,
            myChart2: null,
            myChart3: null,
            myChart4: null,
            myChart5: null,
            userNum: {},
            countReqData: {}
        }
    },
    mounted() {
        //this.getChart1Data()
        this.getChart2Data()
        this.getChart3Data()
        this.getChart4Data()
        this.getChart5Data()
        this.getUserNum()
        //this.initChart1()
        this.initChart2()
        this.initChart3()
        this.initChart4()
        this.initChart5()
    },
    computed: {
        sumReq() {
            if (Object.keys(this.countReqData).length === 0) return 0
            let count = 0
            Object.values(this.countReqData).forEach(item=>{
                count += item
            })
            return count
        }
    },
    methods: {
        async getChart1Data() {
            try {
                const queryParams = {
                    dateType: this.userCountDatevalue
                }
                const r = await totalDynamics(queryParams)
                if (r.code === 0) {
                    const rData = r.data
                    this.myChart1.setOption({
                        xAxis: {
                            data: rData.map(item => item.countDate)
                        },
                        series: [
                            {
                                data: rData.map(item => item.userNum)
                            },
                            {
                                data: rData.map(item => item.vipUserNum)
                            }
                        ]
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getChart2Data() {
            try {
                const queryParams = {
                    dateType: this.reqCountDatevalue
                }
                const r = await reqUpdates(queryParams)
                if (r.code === 0) {
                    const rData = r.data
                    this.myChart2.setOption({
                        xAxis: {
                            data: rData.map(item => item.countDate)
                        },
                        series: [
                            {
                                name: '注册人数',
                                type: 'line',
                                data: rData.map(item => {
                                    let count = 0
                                    Object.values(item).forEach(num => {
                                        if (!isNaN(num)) count += num
                                    })
                                    return count
                                })
                            },
                            {
                                name: 'h5',
                                type: 'line',
                                data: rData.map(item => item.h5)
                            },
                            {
                                name: 'android',
                                type: 'line',
                                data: rData.map(item => item.android)
                            },
                            {
                                name: 'ios',
                                type: 'line',
                                data: rData.map(item => item.ios)
                            },
                            {
                                name: 'windows',
                                type: 'line',
                                data: rData.map(item => item.windows)
                            },
                            {
                                name: 'mac',
                                type: 'line',
                                data: rData.map(item => item.mac)
                            }
                        ]
                    })
                }
                // 获取统计数据
                const countReqData = await countReqUserNum(queryParams)
                if (countReqData.code === 0) this.countReqData = countReqData.data
            } catch (e) {
                console.log(e)
            }
        },
        async getChart3Data() {
            try {
                const queryParams = {
                    date: this.userActiveDay
                }
                const r = await activeTime(queryParams)
                if (r.code === 0) {
                    const chatData = r.data
                    const time = ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
                    const data = []
                    for (let i = 0; i < time.length; i++) {
                        const index = (i + 1) * 3
                        const usersArr = []
                        for (let j = 0; j < chatData.length; j++) {
                            if (chatData[j].hour < index && chatData[j].hour >= index - 3) {
                              usersArr.push(chatData[j].userCount)
                            }
                        }
                        data.push({
                            userCount: Array.from(new Set(usersArr)).length
                        })
                    }
                    this.myChart3.setOption({
                        xAxis: {
                            data: time
                        },
                        series: [
                            {
                                data: data.map(item => item.userCount)
                            }
                        ]
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getChart4Data() {
            try {
                const r = await sexCount()
                if (r.code === 0) {
                    const chatData = r.data
                    this.myChart4.setOption({
                        series: [
                            {
                                data: [
                                    {value: chatData.male, name: '男'},
                                    {value: chatData.female, name: '女'},
                                    {value: chatData.secrecy, name: '保密'}
                                ]
                            }
                        ]
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getChart5Data() {
            try {
                const r = await ageRatio()
                if (r.code === 0) {
                    const chatData = r.data
                    this.myChart5.setOption({
                        series: [
                            {
                                data: [
                                    {value: chatData['1-16'] || 0, name: '16岁以下'},
                                    {value: chatData['17-24'] || 0, name: '17-24岁'},
                                    {value: chatData['25-34'] || 0, name: '25-34岁'},
                                    {value: chatData['45-54'] || 0, name: '45-54岁'},
                                    {value: chatData['55-64'] || 0, name: '55-64岁'},
                                    {value: chatData['65-100'] || 0, name: '65岁以上'},
                                ]
                            }
                        ]
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getUserNum() {
            try {
                const r = await countUserNum()
                this.userNum = r.data
            } catch (e) {
                console.log(e)
            }
        },
        initChart1() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart1 = this.$echarts.init(this.$refs.chat1);
            // 绘制图表
            this.myChart1.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['用户总量', '会员用户'],
                    orient: 'horizontal',
                    bottom: '10',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    top: "25%",
                    containLabel: true
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
                },
                yAxis: [
                    {
                        type: 'value',
                    },
                    {
                        type: 'value',
                    }
                ],
                series: [
                    {
                        name: '用户总量',
                        type: 'line',
                        data: [120, 132, 101, 134, 90, 230, 210, 100]
                    },
                    {
                        name: '会员用户',
                        type: 'line',
                        data: [220, 182, 191, 234, 290, 330, 310, 500]
                    }
                ]
            });
        },
        initChart2() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart2 = this.$echarts.init(this.$refs.chat2);
            // 绘制图表
            this.myChart2.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['注册人数', 'h5', 'android', 'ios', 'windows', 'mac'],
                    orient: 'horizontal',
                    bottom: '10',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    top: "25%",
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: [
                    {
                        type: 'value',
                    },
                    {
                        type: 'value',
                    }
                ],
                series: []
            });
        },
        initChart3() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart3 = this.$echarts.init(this.$refs.chat3);
            // 绘制图表
            this.myChart3.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['活跃人数'],
                    orient: 'horizontal',
                    bottom: '10',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    top: "25%",
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', "21:00-23:59"]
                },
                yAxis: [
                    {
                        type: 'value',
                    },
                    {
                        type: 'value',

                    }
                ],
                series: [
                    {
                        name: '活跃人数',
                        type: 'line',
                        data: [120, 132, 101, 134, 90, 230, 210, 100]
                    }
                ]
            });
        },
        initChart4() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart4 = this.$echarts.init(this.$refs.chat4);
            // 绘制图表
            this.myChart4.setOption({
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '20',
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            {value: 1048, name: '男'},
                            {value: 300, name: '女'},
                            {value: 735, name: '保密'}
                        ],
                        label: {
                            formatter: '{b}:{c}人\n占比{d}%',
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        },
        initChart5() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart5 = this.$echarts.init(this.$refs.chat5);
            // 绘制图表
            this.myChart5.setOption({
                toolbox: {
                    show: true,
                    feature: {
                        mark: {show: true},
                        saveAsImage: {show: true}
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '20',
                },
                series: [
                    {
                        name: '用户年龄',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            {value: 1048, name: '16岁以下'},
                            {value: 300, name: '17-24岁'},
                            {value: 735, name: '25-34岁'},
                            {value: 735, name: '45-54岁'},
                            {value: 735, name: '55-64岁'},
                            {value: 100, name: '65岁以上'},
                        ],
                        label: {
                            formatter: '{b}:{c}人\n占比{d}%',
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.6rem;
  color: #333;
  padding-bottom: 10px;
  font-weight: bold;
  padding-left: 1.4rem;
}

.chat-content {
  box-sizing: border-box;
}

.chat-item {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: .7rem;
  position: relative;
}

.chat-title {
  font-size: 1.29rem;
  color: #333333;
  margin-bottom: 1.4rem;
}

.chat-box {
  background: #f0eeee;
  min-height: 26rem;
}

.totle-data {
  position: absolute;
  top: 160px;
  left: 0;
  z-index: 999;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;

  div {
    flex: 1;

    span {
      margin-right: 20px;
    }
  }

  span {
    color: #333;
  }
}

.search-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;

  .form-label {
    padding-right: 1rem;
  }
}
</style>
